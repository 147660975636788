import axios from 'axios'
// import store from '@/store'
import { getToken } from '../utils/auth'
import { router } from '../router';
import { Dialog } from 'vant';
import { Toast } from 'vant';
// create an axios instance
const service = axios.create({
  // baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  // baseURL: 'https://maobo.fun/', // url = base url + request url
  baseURL: 'https://maojt.cn/', // url = base url + request url
  // baseURL: 'https://maobo.live/', // url = base url + request url
  // baseURL: 'http://127.0.0.1/', // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 60000*3 // request timeout
})

// request interceptor
service.interceptors.request.use(
  config => {
    // do something before request is sent
    // 是否需要设置 token
    const isToken = (config.headers || {}).isToken === false
    console.log('token=', isToken)
    console.log(getToken())
    if (getToken() && !isToken) {
      config.headers['Authorization'] = 'Bearer ' + getToken()
    }
    return config
  },
  error => {
    // do something with request error
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
  */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  response => {
    const res = response.data
    if (res.code === 402) {
      // debugger;
      Toast.fail(res.msg);

      router.push({ path: '/login' })
      // location.href = '/index';
      // this.$router.push({name:'login',params:{id:'参数'}});
    }
    // if the custom code is not 20000, it is judged as an error.
    if (res.code !== 200) {
      Toast.fail(res.msg || 'Error');

      // 50008: Illegal token; 50012: Other clients logged in; 50014: Token expired;
      if (res.code === 50008 || res.code === 50012 || res.code === 50014) {
        // to re-login
        Dialog.confirm({
          title: '标题',
          message:
              'error',
        }).then(() => {
          // store.dispatch('user/resetToken').then(() => {
          //   location.reload()
          // })
        })
      }
      return Promise.reject(new Error(res.message || 'Error'))
    } else {
      return res
    }
  },
  error => {
    console.log('err' + error) // for debug
    Toast.fail(error.message);
    return Promise.reject(error)
  }
)

export default service
